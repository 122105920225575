import React     from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';
import { fetchTopics } from '../actions/topics'

import TopicsList from '../components/TopicsList'



class HomeContainer extends React.Component {

  componentDidMount() {
    if (this.props.topics.length === 0) {
      this.props.onLoadTopics();
    }
  }

  render() {
    return(
      <div>
          <TopicsList
            topics={this.props.topics}
            hasError={this.props.hasErrorT}
            isLoading={this.props.isLoadingT}
            />
      </div>
    )
  }
}

HomeContainer.propTypes = {
  onLoadTopics   : PropTypes.func.isRequired,
  topics        : PropTypes.array.isRequired,
  hasErrorT     : PropTypes.bool.isRequired,
  isLoadingT    : PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  topics       : state.topics,
  hasErrorT    : state.getTopicsError,
  isLoadingT   : state.loadTopics,
});

const mapDispatchToProps= dispatch => ({
  onLoadTopics    : () => dispatch(fetchTopics()),
}); 


export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
