import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route ,Switch } from 'react-router-dom';

// 各ページのインポート
import HomeContainer     from './containers/HomeContainer';
import CalendarContainer   from './containers/CalendarContainer';
import SearchContainer   from './containers/SearchContainer';
import FeedbackContainer from './containers/FeedbackContainer';
import AboutContainer    from './containers/AboutContainer';
import BottomContainer   from './containers/BottomContainer';
import Notification      from './containers/Notification';

// タイトル
import hinafesimg from './res/hinafes.jpg';
import titleimage from './res/title.png';
import iconimage  from './res/hinasoralogoicon.png';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
  approot: {
    textAlign: 'center',
    margin: '0',
    padding: '0',
    width  : '100vw',
  },

 '@keyframes rootover': {
    '0%' : { backgroundColor: 'rgba(255,255,255,1.0)' },
    '20%' : { backgroundColor: 'rgba(255,255,255,1.0)' },
    '40%' : { backgroundColor: 'rgba(255,255,255,1.0)' },
    '60%' : { backgroundColor: 'rgba(255,255,255,0.7)' },
    '80%' : { backgroundColor: 'rgba(255,255,255,0.4)' },
    '100%' : { backgroundColor: 'rgba(255,255,255,0.0)' },
  },

  apptitleback: {
    position:'fixed',
    top:'0',
    left:'0',
    margin: '0',
    padding: '0',
    width  : '100%',
    height : '100vw',
    backgroundColor:'#FFFFFF',
    backgroundImage: "url(" + hinafesimg + ")",
    backgroundSize : '100%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  },

  appmain: {
    position:'absolute',
    top:'0',
    left:'0',
    margin : '0',
    padding: '0',
  },

  apptitleover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top:'0',
    left:'0',
    margin : '0',
    padding: '0',
    width  : '100%',
    height : '48vw',
    backgroundColor: 'rgba(255,255,255,0.0)',
    animation: '$rootover 1s linear',
    animationFillMode:'both',
  },

  '@keyframes title': {
    '0%' : { backgroundColor: 'rgba(255,255,255,0.0)' },
    '20%' : { backgroundColor: 'rgba(255,255,255,0.6)' },
    '40%' : { backgroundColor: 'rgba(255,255,255,1.0)' },
    '60%' : { backgroundColor: 'rgba(255,255,255,0.6)' },
    '80%' : { backgroundColor: 'rgba(255,255,255,0.4)' },
    '100%' : { backgroundColor: 'rgba(255,255,255,0.3)' },
  },

  apptitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin : '0',
    padding: '0',
    width  : '90%',
    height : '80%',
    backgroundColor: 'rgba(255,255,255,.3)',
    animation: '$title 3s linear',
    animationFillMode:'both',
  },
  titleimg: {
    width: '80%',
    filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,.6))',
  },
  hinasorabtn: {
    marginTop: '50px',
  },
  hinasoraimg: {
    height:'5vh',
    opacity: '1.0',
    filter: 'drop-shadow(-1px -1px 1px rgba(0,0,0,0.4))',
  },
  appcontentsroot: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0',
    paddingTop: '10px',
    paddingBottom: '80px',
//    backgroundColor: 'rgba(250, 250, 250,1.0)',
    background:'linear-gradient(170deg, #F6F6FC 0%, #FCF6F3 100%) no-repeat 50% 50% / 100% 100%',
    boxShadow: '0px -5px 5px 0px rgba(0,0,0,0.4) ',
    lineHeight: '0em',
  },

}));

const render = (Component) => ({ history, match }) => {
  window.scroll(0, 0);
  return <Component history={history} match={match} />;
};


function App(appmainRef) {
  const classes = useStyles();
  
  return (
    <div className={classes.approot}>
      <div className={classes.apptitleback}> </div>
      <div className={classes.appmain}>
        <div className={classes.apptitleover}>
          <div className={classes.apptitle}>
            <img src={titleimage} className={classes.titleimg} alt=""  />
          </div>
        </div>
        <div className={classes.appcontentsroot}>
          <Switch>
            <Route path='/home'  render={render(HomeContainer)} />
            <Route path='/calendar'  render={render(CalendarContainer)} />
            <Route path='/search'  render={render(SearchContainer)} />
            <Route path='/feedback'  render={render(FeedbackContainer)} />
            <Route path='/about'  render={render(AboutContainer)} />
            <Route render={render(HomeContainer)} />
          </Switch>
          <Button aria-label="hinasora.com" className={classes.hinasorabtn} href="https://hinasora.com/" target="_blank" rel="noopener noreferrer">
            <img src={iconimage}  alt="hinasora.com" className={classes.hinasoraimg} />&nbsp;hinasora.com
          </Button>
        </div>
      </div>
      <Notification variant="success" />
      <BottomContainer />
    </div>
  );
}

export default App;
