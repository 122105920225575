import * as actionTypes from './actionTypes';

export const notification_set = (variant, message) => ({
    type: actionTypes.NOTIFICATION_SET,
    ntc_isOpen : true,
    ntc_variant: variant,
    ntc_message: message,
});

export const notification_close = () => ({
    type: actionTypes.NOTIFICATION_CLOSE,
    ntc_isOpen : false,
});
