import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import FesSnackBar from '../components/FesSnackBar';

// Redux関連
import { connect } from 'react-redux';
import { notification_close } from '../actions/notification'

const useStyles = makeStyles(theme => ({
    ntcroot:{
        margin: 10,
      },
}));

function Notification(props) {
    const classes = useStyles();

    const { onClose, isOpen , variant, message } = props;
    
    return (
    <Snackbar
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
        }}
        open={isOpen}
        autoHideDuration={2000}
        onClose={() => { onClose(); }}
        className={classes.ntcroot}
    >
        <FesSnackBar
        onClose={() => { this.props.onClose(); }
        }
        variant={variant}
        message={message}
        />
    </Snackbar>
    );
}

Notification.propTypes = {
    onClose   : PropTypes.func.isRequired,
    isOpen    : PropTypes.bool.isRequired,
    variant   : PropTypes.string.isRequired,
    message   : PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
    isOpen    : state.ntc_isOpen,
    variant   : state.ntc_variant,
    message   : state.ntc_message,
});

const mapDispatchToProps = dispatch => ({
    onClose         : () => dispatch(notification_close()),
}); 

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
