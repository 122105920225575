import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// material-ui
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';
import lightBlue from '@material-ui/core/colors/lightBlue';
import CssBaseline from '@material-ui/core/CssBaseline'

// Redux関連
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducers';
import thunk from 'redux-thunk'

// Router
import { BrowserRouter as Router } from 'react-router-dom';



// Redux設定
const composeEnhancers = compose; // 本番用
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk),
  )
);

// テーマ設定
const theme = createMuiTheme({
    palette: {
        type   : 'light',
        primary: deepOrange,
        secondary: lightBlue,
    },
    typography: {
        button: {
            textTransform: "none"
        }
    },
    props: {
        MuiTextField: {
            variant: "outlined"
        },
        MuiCheckbox: {
            color: "primary"
        },
        MuiRadio: {
            color: "primary"
        },
        MuiSwitch: {
            color: "primary"
        },
    },
});


ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme} >
      <Router>
      <CssBaseline />
        <App/>
      </Router>
    </MuiThemeProvider>
  </Provider>
  , document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
