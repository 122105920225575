import React from 'react';
import { Link ,withRouter} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

// BottomNavigation
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import PetsIcon from '@material-ui/icons/PetsOutlined';
import TodayIcon from '@material-ui/icons/TodayOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CreateIcon from '@material-ui/icons/CreateOutlined';


const useStyles = makeStyles(theme => ({
        bottomnavigation: {
                textAlign: 'center',
                width: '100vw',
                position: 'fixed',
       //         paddingLeft:10,
       //         paddingRight:10,
                backgroundColor: 'rgba(255, 245, 235, 0.95)',
                boxShadow: '0px -5px 5px 0px rgba(0,0,0,0.4) ',
                bottom: 0,
                left: 0,
      //          zIndex: 1000,
              },
              bottomnavigationitem: {
                padding: '16px 8px 8px 8px',
                minWidth: '65px',
              },
}));

function BottomContainer(props) {
    const classes = useStyles();
  
    return (
        <BottomNavigation
          value={props.location.pathname}
          className={classes.bottomnavigation}
          >
          <BottomNavigationAction 
                  component={Link}
                  to="/"
                  value="/"
                  className={classes.bottomnavigationitem}
                  icon={<InfoIcon />} />
          <BottomNavigationAction 
                  component={Link}
                  to="/calendar"
                  value="/calendar"
                  className={classes.bottomnavigationitem}
                  icon={<TodayIcon />} />
          <BottomNavigationAction 
                  component={Link}
                  to="/search"
                  value="/search"
                  className={classes.bottomnavigationitem}
                  icon={<SearchIcon />} />
          <BottomNavigationAction 
                  component={Link}
                  to="/feedback"
                  value="/feedback"
                  className={classes.bottomnavigationitem}
                  icon={<CreateIcon />} />
          <BottomNavigationAction 
                  component={Link}
                  to="/about"
                  value="/about"
                  className={classes.bottomnavigationitem}
                  icon={<PetsIcon />} />
        </BottomNavigation>
    );
  }

export default withRouter(BottomContainer)
