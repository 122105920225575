import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import NoteIcon from '@material-ui/icons/NoteAddOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CopyToClipboard from "react-copy-to-clipboard";
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Paper from '@material-ui/core/Paper';

import { connect } from 'react-redux';
import { notification_set } from '../actions/notification'

import deepOrange from '@material-ui/core/colors/deepOrange';
import teal       from '@material-ui/core/colors/teal';
import yellow     from '@material-ui/core/colors/yellow';
import pink       from '@material-ui/core/colors/pink';
import indigo     from '@material-ui/core/colors/indigo';
import blueGrey   from '@material-ui/core/colors/blueGrey';

import FesDialog from './FesDialog'

const useStyles = makeStyles(theme => ({
    card: {
      width: 343,
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign:'left',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    breadcrumbs:{
    },
    paper:{
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: "#F0F0F0",
    },
    fesAvatar: {
      margin: 10,
      color: '#fff',
      backgroundColor: deepOrange[500],
    },
    sloganAvatar: {
      margin: 10,
      color: '#fff',
      backgroundColor: teal[500],
    },
    groupAvatar: {
      margin: 10,
      color: '#fff',
      backgroundColor: yellow[500],
    },
    charityAvatar: {
      margin: 10,
      color: '#fff',
      backgroundColor: pink[500],
    },
    contestAvatar: {
      margin: 10,
      color: '#fff',
      backgroundColor: indigo[500],
    },
    otherAvatar: {
      margin: 10,
      color: '#fff',
      backgroundColor: blueGrey[500],
    },
    cardcontent1: {
      paddingTop : 0,
      paddingBottom: 0,
    },
    cardcontent2: {
      fontSize : '1em',
      paddingTop : 0,
      paddingBottom: 0,
    },
    taglink: {
      fontSize : '1em',
      lineHeight: '2em',
    },

}));

function getCookie() {
  var result = '';
  var cookieName = 'FAVS=';
  var allcookies = document.cookie;

  var position = allcookies.indexOf( cookieName );
  if( position !== -1 )
  {
      var startIndex = position + cookieName.length;

      var endIndex = allcookies.indexOf( ';', startIndex );
      if( endIndex === -1 )
      {
          endIndex = allcookies.length;
      }

      result = decodeURIComponent(
          allcookies.substring( startIndex, endIndex ) );
  }

  return result;
}

function isFav(fesId) {
  var arr = getCookie().split(',');
  if (arr.indexOf(String(fesId)) >= 0){
    return true;
  } else {
    return false;
  }
}

function setCookie(fesId,mode) {
  const arr = getCookie().split(',');

  let newArray = arr.filter(n => n !== ("" + fesId));
  if (mode) {
    newArray.push(fesId);
  }

  const expire = new Date();
  expire.setTime( expire.getTime() + 1000 * 3600 * 24 * 365);

  const cookieset = 'FAVS=' + encodeURIComponent(newArray.toString())
                  + ';path=./; expires=' + expire.toUTCString();
  document.cookie = cookieset;

}


const typeToAvatarString = {
  fes     : '祭',
  slogan  : '言',
  group   : '仲',
  charity : '慈',
  contest : '競',
  other   : '他',
};


function FesCard(props) {
    const classes = useStyles();

    const { festival, onCopy } = props;

    const [expanded, setExpanded] = React.useState(false);
    const [isFavorite, setFavorite] = React.useState(isFav(festival.fesId));
    const [open, setOpen] = React.useState(false);
    const [tagName, setTagName] = React.useState("");

    function handleExpandClick() {
      setExpanded(!expanded);
    }

    function handleFavClick() {
      setCookie(festival.fesId,!isFavorite);
      setFavorite(!isFavorite);
    }

    function dialogClose() {
      setOpen(false);
    }
  
    function dialogOpen(prmTagName) {
      setTagName(prmTagName);
      setOpen(true);
    }
  
    return (
      <React.Fragment>
      <Card className={classes.card}>
        <CardHeader
            avatar={
                <Avatar aria-label="Recipe" className={classes[festival.fesType + "Avatar"]}>
                {typeToAvatarString[festival.fesType]}
                </Avatar>
            }
            action={
                <React.Fragment>
                <CopyToClipboard onCopy={(text,success)=>{onCopy('simple','copied : ' + text);}} text={festival.fesTagCopy}>
                <IconButton aria-label="clip">
                <NoteIcon />
                </IconButton>
                </CopyToClipboard>
                <IconButton aria-label="fav" onClick={handleFavClick}>
                {isFavorite ? <FavoriteIcon color='primary' /> : <FavoriteIcon />}
                </IconButton>
                </React.Fragment>
            }
            title={festival.fesName}
            subheader={festival.fesSchedule}
        />
        <CardContent className={classes.cardcontent1}>
        
        {(() => {
            let term = "";
            if (festival.fesStart !== "" || festival.fesEnd !== "") {
              term = "期　間：" + festival.fesStart + " ～ " + festival.fesEnd;
            }
            return (
              <Typography variant="body2" color="textSecondary">{term}</Typography>
              )
          })()}
        {(() => {
            const arr = festival.fesDescription.split(/\r\n|\n/);
            return (
              <div>
                {arr.map((txt, index) => {
                 if (txt === "") { txt = "　"; }
                 return (
                 <Typography variant="body2" color="textSecondary" key={index}>{txt.replace(/%%/g, '#').replace(/##/g, '#').replace(/@@/g, '@')}</Typography>
                 )
                 })}
              </div>
              )
          })()}
        </CardContent>
        <CardActions disableSpacing>
          <Paper elevation={1} className={classes.paper}>
            <Breadcrumbs aria-label="GotoInstagram" className={classes.breadcrumbs}>
              <Link color="secondary" href={"instagram://tag?name=" + festival.fesTagName}>
                アプリ
              </Link>
              <Link color="secondary" href={"https://www.instagram.com/explore/tags/" + festival.fesTagName} target="_blank" rel="noopener noreferrer" >
                ブラウザ
              </Link>
            </Breadcrumbs>
          </Paper>
          
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Show more"
          >
           <Typography variant="caption" color="textSecondary">more...</Typography>
           <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.cardcontent2}>
          {(() => {
            const arr = festival.fesDetail.split(/\r\n|\n/);
            return (
              <div>
                {arr.map((txt, index) => {
                 if (txt === "") { txt = "　"; }
                 return (
                 <Typography variant="body2" color="textSecondary" key={index}>{txt.replace(/%%/g, '#').replace(/##/g, '#').replace(/@@/g, '@')}</Typography>
                 )})}
              </div>
              )
          })()}
          {(() => {
            const festext = festival.fesDescription + "  " + festival.fesDetail;
            let arr1 = festext.match(/@@[^#@\s]+/g);
            let arr2 = festext.match(/%%[^#@\s]+/g);
            if(arr1 === null && arr2 === null) {return "";}
            if(arr1 === null){arr1 = [];}
            if(arr2 === null){arr2 = [];}
            return (
              <Breadcrumbs aria-label="GotoInstagram" className={classes.taglink}>
                <Typography variant="caption" color="textSecondary">IG-Link</Typography>
                {arr1.map((txt, index) => (
                 <Link color="secondary" href={"https://www.instagram.com/" + txt.slice(2)} key={index} target="_blank" rel="noopener noreferrer" >{txt.slice(1)}</Link>
                 ))}
                {arr2.map((txt, index) => (
                 <Link color="secondary" href={"https://www.instagram.com/explore/tags/" + txt.slice(2)} key={index} target="_blank" rel="noopener noreferrer" >{"#" + txt.slice(2)}</Link>
                 ))}
              </Breadcrumbs>
              )
          })()}
          {(() => {
            const festext = festival.fesDescription + "  " + festival.fesDetail;
            let arr = festext.match(/##[^#@\s]+/g);
            if(arr === null) {return "";}
            return (
              <Breadcrumbs aria-label="openDialog" className={classes.taglink}>
                <Typography variant="caption" color="textSecondary">Details</Typography>
                {arr.map((txt, index) => (
                 <Link color="secondary" key={index} href={"#"}
                 onClick={() => dialogOpen(txt.slice(2))}>
                 {txt.slice(1)}
                 </Link>
                 ))}
              </Breadcrumbs>
              )
          })()}
          </CardContent>
        </Collapse>
      </Card>
      <FesDialog
            open={open}
            tagName={tagName}
            close={() => dialogClose([])}
      />
      </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  onCopy         : (variant,message) => dispatch(notification_set(variant,message)),
});


export default connect(mapStateToProps, mapDispatchToProps)(FesCard);
