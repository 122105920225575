import React from 'react';
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';

// リスト
import blueGrey   from '@material-ui/core/colors/blueGrey';

// ダイアログ
import Dialog from '@material-ui/core/Dialog';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import FesCard from './FesCard'


const useStyles = makeStyles(theme => ({
    topicsroot: {
      marginTop: 10,
      marginBottom: 50,
      textAlign:'center',
    },
    topicstitle: {
      textAlign:'center',
      lineHeight: '1em',
    },
    topicsmain: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    topicslist: {
      width:'90vw',
    },
  
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    progress: {
      margin: 8,
    },
    appBar: {
      position: 'relative',
      backgroundColor:'#666666',
    },
    dialogroot: {
    },
    dialogtitle: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    dialogcontent: {
      paddingTop: '5vw',
      paddingBottom: '10vw',
      bottom       : 0,
      overflow     : 'auto',
      height       : '100%',
      background:'linear-gradient(170deg, #F6F6FC 0%, #FCF6F3 100%) no-repeat 50% 50% / 100% 100%',
    },
    dialogcaption: {
      padding      : '5vw',
      paddingTop   : '0vw',
      border:1,
    },
    dialogwarn: {
      padding      : '3vw',
      paddingTop   : '0vw',
      paddingBottom   : '0vw',
      margin       : '2vw',
      marginBottom : '5vw',
      border  : '1px solid #FCB',
      borderRadius: '5px',
      backgroundColor:'#FFF6F0',
    },
  
    listitemavatar: {
      margin  : 0,
      minWidth: 40,
      padding : 5,
    },
    avatar: {
      margin: 0,
      width: 30,
      height: 30,
      backgroundColor:blueGrey[500],
    },
  
}));

const Transition = React.forwardRef(function Transition(props, ref) {
return <Fade in='true' ref={ref} {...props} />;
});
  
function FesDialog(props) {
    const classes = useStyles();

    const [values, setValues] = React.useState({
        isLoading: false,
        tagName: "",
        feslist: [],
    });

    function getFes(tagname){

        const url = "https://www.hinasora.com/api/fes/getFesByTagName.php?tag=" + tagname;

        setValues(oldValues => ({
        ...oldValues,
            'isLoading': true,
            'tagName'  : tagname,
            'feslist'  : [],
        }));

        fetch(url)
        .then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
            }
            setValues(oldValues => ({
            ...oldValues,
            'isLoading': false,
            }));
            return response;
        })
        .then((response) => response.json())
        .then((feslist) => {
            setValues(oldValues => ({
            ...oldValues,
            'feslist': feslist,
            }));
        })
        .catch((e) => {
            console.info(e);
        });
    }

    if(props.tagName && props.tagName !== values.tagName){
        getFes(props.tagName);
    }

    return (
    <Dialog fullScreen disableEscapeKeyDown open={props.open} onClose={() => props.close()}  TransitionComponent={Transition}>
    <AppBar className={classes.appBar}>
        <Toolbar>
        <IconButton edge="start" color="inherit" onClick={() => props.close()} aria-label="Close">
            <CloseIcon />
        </IconButton>
        <Typography variant="subtitle1" className={classes.dialogtitle}>
        {props.tagName}
        </Typography>
        </Toolbar>
    </AppBar>
    <div className={classes.dialogcontent}>
    <div className={classes.dialogwarn}>
    <Typography variant="body2" color="error">【注】左上の✖ボタンで戻ってください</Typography>
    <Typography variant="caption" color="secondary">スワイプバックや戻るボタンではうまく戻れません<span aria-label="cat" role="img">😿</span>
    そのうちなんとかします・・・</Typography>
    </div>

    {(() => {
        if(props.caption){
        const arr = props.caption.split(/\r\n|\n/);
        return (
            <div className={classes.dialogcaption}>
            {arr.map((txt, index) => {
                if (txt === "") { txt = "　"; }
                return (
                <Typography variant="body2" color="textSecondary" key={index}>{txt}</Typography>
                )})}
            </div>
            )
        } else {
            return "";
        }
    })()}
    {(() => {
        if (values.feslist.length > 0 && props.tagName) {
        return (
            <FesCard festival={values.feslist[0]}/>
            )
        } else {
            return "";
        }
    })()}
    </div>
    </Dialog>
    );
}

  
export default FesDialog;
