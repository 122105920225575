import React     from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { schedule_setCalender } from '../actions/schedule'

import ScheduleList from '../components/ScheduleList'


class CalendarContainer extends React.Component {

  componentDidMount() {
    if (this.props.schedule.length === 0) {
      this.props.onChangeCalender(this.props.calendarDate);
    }
  }

  onChangeCalender(after) {
    if (after !== "" && after !== this.props.calendarDate) {
      this.props.onChangeCalender(after);
    }
  }

  render() {

    return(
      <div>
          <ScheduleList
            schedule={this.props.schedule}
            hasError={this.props.hasErrorS}
            isLoading={this.props.isLoadingS}
            calendarDate={this.props.calendarDate}
            onChangeFunc={(val) => {
                if (val !== "" && val !== this.props.calendarDate) {
                  this.props.onChangeCalender(val);
                }
              }
            }
            />
      </div>
    )
  }
}

CalendarContainer.propTypes = {
  onChangeCalender : PropTypes.func.isRequired,
  schedule      : PropTypes.array.isRequired,
  hasErrorS     : PropTypes.bool.isRequired,
  isLoadingS    : PropTypes.bool.isRequired,
  calendarDate  : PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  schedule     : state.schedule,
  hasErrorS    : state.schedule_getError,
  isLoadingS   : state.schedule_load,
  calendarDate : state.calendarDate,
});

const mapDispatchToProps= dispatch => ({
  onChangeCalender: (prmdate) => dispatch(schedule_setCalender(prmdate)),
}); 


export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer);
