import * as actionTypes from '../actions/actionTypes';

export const schedule_getError = status => ({
    type: actionTypes.SCHEDULE_GET_ERROR,
    hasError: status
})

export const schedule_load = status => ({
    type: actionTypes.SCHEDULE_LOAD,
    isLoading: status
})

export const schedule_fetchSuccess = schedule => ({
    type: actionTypes.SCHEDULE_FETCH_SUCCESS,
    schedule
})

export const schedule_changeDate = calendarDate => ({
    type: actionTypes.SCHEDULE_CHG_DATE,
    calendarDate
})

export const schedule_setCalender = prmdate => {
    return (dispatch) => {
      dispatch(schedule_changeDate(prmdate));
      dispatch(schedule_load(true));

      const url = 'https://www.hinasora.com/api/fes/getFesByDate.php?srchprm=' + prmdate;
      fetch(url)
        .then((response) => {
          if(!response.ok) {
            throw Error(response.statusText);
          }
          dispatch(schedule_getError(false));
          dispatch(schedule_load(false));
  
          return response;
        })
        .then((response) => response.json())
        .then((schedule) => dispatch(schedule_fetchSuccess(schedule)))
        .catch(() => dispatch(schedule_getError(true)));
    }
}
