import * as actionTypes from '../actions/actionTypes';

export const ntc_isOpen = (state = false, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_SET:
    case actionTypes.NOTIFICATION_CLOSE:
      return action.ntc_isOpen;
    default:
      return state;
  }
}

export const ntc_variant = (state = 'success', action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_SET:
      return action.ntc_variant;
    default:
      return state;
  }
}

export const ntc_message = (state = '', action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_SET:
      return action.ntc_message;
    default:
      return state;
  }
}
