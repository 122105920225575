// TOPICS関連
export const TOPICS_GET_ERROR     = 'TOPICS_GET_ERROR';
export const TOPICS_LOAD          = 'TOPICS_LOAD';
export const TOPICS_FETCH_SUCCESS = 'TOPICS_FETCH_SUCCESS';

// SCHEDULE関連
export const SCHEDULE_GET_ERROR     = 'SCHEDULE_GET_ERROR';
export const SCHEDULE_LOAD          = 'SCHEDULE_LOAD';
export const SCHEDULE_FETCH_SUCCESS = 'SCHEDULE_FETCH_SUCCESS';
export const SCHEDULE_CHG_DATE      = 'SCHEDULE_CHG_DATE';

// FESLIST関連
export const FESLIST_GET_ERROR     = 'FESLIST_GET_ERROR';
export const FESLIST_LOAD          = 'FESLIST_LOAD';
export const FESLIST_FETCH_SUCCESS = 'FESLIST_FETCH_SUCCESS';

// Snackbar関連
export const NOTIFICATION_SET     = 'NOTIFICATION_SET';
export const NOTIFICATION_CLOSE   = 'NOTIFICATION_CLOSE';

