import React from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FesCard from './FesCard'

// スタイル
const useStyles = makeStyles(theme => ({
    schelistroot: {
        textAlign:'center',
    },
    schelisttitle: {
      textAlign:'center',
      lineHeight: '0.3em',
    },  
      schelistmain: {
        marginTop: 10,
        marginBottom: 10,
          display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      },
    }));

function ScheduleList(props) {
    const classes = useStyles();
    const { onChangeFunc, schedule, hasError, isLoading ,calendarDate} = props;

    if (isLoading) {
        return (
          <div className={classes.schelistroot}>
            <div>
              <h3>お祭りカレンダー</h3>
              <TextField
              id="date"
              type="date"
              label=""
              defaultValue={calendarDate}
              InputLabelProps={{
                shrink: true,
              }}
              margin="none"
              variant="standard"
              required
              onChange={(event) => onChangeFunc(event.target.value)}
            />
            </div>
            <div className={classes.schelistmain}>
              <CircularProgress className={classes.progress} color="secondary" />
            </div>
          </div>

        );
    }

    if (hasError) {
      return (
          <div className={classes.schelistroot}>
          <div>
            <h3>お祭りカレンダー</h3>
            <TextField
            id="date"
            type="date"
            label=""
            defaultValue={calendarDate}
            InputLabelProps={{
              shrink: true,
            }}
            margin="none"
            variant="standard"
            required
            onChange={(event) => onChangeFunc(event.target.value)}
          />
          </div>
            <div className={classes.schelistmain}>
            <p>データの取得に失敗しました</p>
            </div>
          </div>
          );
    }

    return (
        <div className={classes.schelistroot}>
        <div>
            <h3 className={classes.schelisttitle}>お祭りカレンダー</h3>
            <Typography variant="caption" display="block" color="secondary" >開催頻度が少ないものほど上に出ます</Typography>
            <Typography variant="caption" display="block" color="secondary" >また、毎日開催のものは出てきません</Typography>
            <TextField
              id="date"
              type="date"
              label=""
              defaultValue={calendarDate}
              InputLabelProps={{
                shrink: true,
              }}
              margin="none"
              variant="standard"
              required
              onChange={(event) => onChangeFunc(event.target.value)}
            />
        </div>
        <div className={classes.schelistmain}>
        {schedule.map((festival) => (
            <FesCard festival={festival} key={festival.fesId}/>
        ))}
        </div>
        </div>
    );
}

ScheduleList.propTypes = {
    onChangeFunc : PropTypes.func.isRequired,
    schedule     : PropTypes.array.isRequired,
    hasError     : PropTypes.bool.isRequired,
    isLoading    : PropTypes.bool.isRequired,
    calendarDate : PropTypes.string.isRequired,
};

export default ScheduleList;
