import { combineReducers } from 'redux';
import { getTopicsError, loadTopics, topics } from './topics';
import { schedule_getError, schedule_load, schedule, calendarDate } from './schedule';
import { ntc_isOpen, ntc_variant, ntc_message } from './notification';

export default combineReducers({
    getTopicsError,
    loadTopics,
    topics,
    schedule_getError, 
    schedule_load, 
    schedule, 
    calendarDate,
    ntc_isOpen,
    ntc_variant,
    ntc_message,
});
