import * as actionTypes from '../actions/actionTypes';

export const getTopicsError = status => ({
    type: actionTypes.TOPICS_GET_ERROR,
    hasError: status
})

export const loadTopics = status => ({
    type: actionTypes.TOPICS_LOAD,
    isLoading: status
})

export const fetchTopicsSuccess = topics => ({
    type: actionTypes.TOPICS_FETCH_SUCCESS,
    topics
})

export const fetchTopics = () => {
    return (dispatch) => {
      dispatch(loadTopics(true));

      fetch('https://www.hinasora.com/api/fes/getInfo.php')
        .then((response) => {
          if(!response.ok) {
            throw Error(response.statusText);
          }
          dispatch(loadTopics(false));
  
          return response;
        })
        .then((response) => response.json())
        .then((topics) => dispatch(fetchTopicsSuccess(topics)))
        .catch(() => dispatch(getTopicsError(true)));
    }
}
