import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  aboutroot: {
    marginTop: 10,
    marginBottom: 10,
    textAlign:'center',
  },
  abouttitle: {
    textAlign:'center',
    lineHeight: '2em',
  },
  abouttext: {
    width : '80vw',
    maxWidth:'500px',
    margin: '0 auto',
    textAlign:'left',
  },

}));


function AboutContainer() {

  const classes = useStyles();

  return (

    <div className={classes.aboutroot}>

      <h3 className={classes.abouttitle}>このサイトについて</h3>

      <div className={classes.abouttext}>

      <Typography variant="body2"          >インスタグラムで猫アカウントを運営されているみなさん、こんにちは ☀</Typography>
      <Typography variant="body2" paragraph>おまつり猫のひなたと、付き合わされてる弟のそらです<span aria-label="cat" role="img">😺🐱🐾</span></Typography>

      <Typography variant="body2"          >猫さんのお祭り、参加したことありますか？</Typography>
      <Typography variant="body2" paragraph>ひなはお祭り大好き<span aria-label="heart" role="img">💕</span> 普段接点のない人にも写真を見てもらえるし、
      参加者同士でお友達になれたり、いつも同じような写真だなーってマンネリ化してくるインスタライフへの刺激にもなります<span aria-label="cat" role="img">🍀</span></Typography>

      <Typography variant="body2"          >でも猫さんのお祭りってたくさんありますよね。</Typography>
      <Typography variant="body2" paragraph>ひなも最初はびっくりしました。</Typography>

      <Typography variant="body2"          >びっくりの次は、戸惑いです。</Typography>
      <Typography variant="body2"          >勝手に参加していいの？参加資格や条件は？</Typography>
      <Typography variant="body2"          >どういう写真を使えばいいの？</Typography>
      <Typography variant="body2" paragraph>主催者さん？ご挨拶したほうがいい？誰？</Typography>

      <Typography variant="body2" paragraph>よくわからないからやめた。 ← これ</Typography>
      
      <Typography variant="body2"          >少し慣れたら次はこんな感じ。</Typography>
      <Typography variant="body2"          >今日はどんなお祭りがあるの？</Typography>
      <Typography variant="body2"          >こんなテーマのお祭りってないの？</Typography>
      <Typography variant="body2" paragraph>新しいお祭りにチャレンジしてみたいけど探しづらい・・・めんどくさい。</Typography>

      <Typography variant="body2" paragraph>いつものやつだけでいいや。 ← これ</Typography>

      <Typography variant="body2"          >お祭り初級者～中級者の「← これ」をなくして、より多くの猫さんでお祭りを盛り上げていきたい！</Typography>
      <Typography variant="body2"          >そのためのガイドが欲しい！</Typography>
      <Typography variant="body2" paragraph>そう考えて立ち上げたのがこのサイトです。</Typography>

      <Typography variant="body2"          >そんなひなも、経験は初級者並み。</Typography>
      <Typography variant="body2" paragraph>先輩方のご意見、ご指摘、お祭り情報など、絶賛募集中です <span aria-label="mail" role="img">📮___💌</span></Typography>

      <Typography variant="body2"          >情報提供にあたっては、お手数ですが【<span aria-label="pen" role="img">✎</span>掲載依頼の受付とサイトポリシー】 を必ずお読みください。</Typography>
      <Typography variant="body2" paragraph>現状、データ入力が間に合っておりません。徐々に充実させていきますので気長にお付き合いください<span aria-label="sorry" role="img">🙇💦</span></Typography>

      <Typography variant="body2" paragraph>それでは、ひなたと、そらと、hinasora.com がお送りする「#ひな<span aria-label="catspad" role="img">🐾</span>まつり」。どうぞご愛顧のほどよろしくお願いします<span aria-label="clover" role="img">🍀</span></Typography>
      <Typography variant="body2" paragraph>みんなでわっしょ～い <span aria-label="festival" role="img">🎆🎊🎉</span></Typography>

      </div>

    </div>
  );
}

export default AboutContainer;
