import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import indigo from '@material-ui/core/colors/indigo';

import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';


const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
success: {
    backgroundColor: green[600],
},
error: {
    backgroundColor: red[700],
},
info: {
    backgroundColor: indigo[700],
},
warning: {
    backgroundColor: amber[700],
},
simple: {
},
icon: {
    fontSize: 20,
},
iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
},
message: {
    display: 'flex',
    alignItems: 'center',
},
}));

function FesSnackBar(props) {
    const classes = useStyles();

    const { message, onClose, variant, ...other } = props;
    
    if (variant === 'simple') {
        return (
            <SnackbarContent
              className={classes[variant]}
              aria-describedby="client-snackbar"
              message={
                <span className={classes.message}>
                  {message}
                </span>
              }
            />
          );

    } else {
        const Icon = variantIcon[variant];
        return (
            <SnackbarContent
              className={classes[variant]}
              aria-describedby="client-snackbar"
              message={
                <span className={classes.message}>
                  <Icon className="{classes[variant] classes.icon}" />
                  {message}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={onClose}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
              {...other}
            />
          );
    }
}
  
FesSnackBar.propTypes = {
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'simple']).isRequired,
};

export default FesSnackBar;
