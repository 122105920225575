import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import FesCard from '../components/FesCard'

const types = [
  {value: 'free',label: 'フリーワード検索',},
  {value: 'fav',label: 'お気に入り',},
  {value: 'fes',label: 'お祭り・イベント',},
  {value: 'group',label: 'クラブ・グループ',},
  {value: 'slogan',label: 'ﾊﾟﾜｰﾜｰﾄﾞ・合言葉',},
  {value: 'charity',label: 'チャリティー',},
  {value: 'contest',label: 'コンテスト',},
  {value: 'other',label: 'その他',},
];

const useStyles = makeStyles(theme => ({
  srchroot: {
    marginTop: 0,
    marginBottom: 10,
    minHeight:'100vh',
    textAlign:'center',
  },

  srchform: {
    width:'95vw',
    textAlign:'left',
    margin: 'auto',
  },

  srchtitle: {
    textAlign:'center',
    lineHeight: '0.3em',
  },

  inputmain: {
    width:'50%',
    margin: theme.spacing(1),
  },
  
  inputsub: {
    width:'65%',
    margin: theme.spacing(1),
  },
  
  inputcls: {
    margin: theme.spacing(1),
  },

  button: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  
  progress: {
    margin: 8,
  },

  srchresults: {
    textAlign:'center',
    paddingTop:'5vh'
  },

}));



function getCookie() {
  var result = '';
  var cookieName = 'FAVS=';
  var allcookies = document.cookie;
  var position = allcookies.indexOf( cookieName );
  if( position !== -1 )
  {
      var startIndex = position + cookieName.length;

      var endIndex = allcookies.indexOf( ';', startIndex );
      if( endIndex === -1 )
      {
          endIndex = allcookies.length;
      }

      result = decodeURIComponent(
          allcookies.substring( startIndex, endIndex ) );
  }
  return result;
}


function SearchContainer() {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    category: 'free',
    subcategory: '',
    srchwords: '',
    getclosed: false,
    isLoading: false,
    message   : "開発中β版です...",
    feslist: [],
  });

  function handleChange(event) {
    event.persist();
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }

  function handleChangeChecked(event) {
    event.persist();
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.checked,
    }));
  }

  function getList(){

    if(values.category === 'free' && values.srchwords === '') {
      return;
    }

    let srchwords = values.srchwords;
    if (values.category === 'fav') {
      srchwords = getCookie();
    }

    const param = "?category=" + values.category
    + "&subcategory=" + values.subcategory
    + "&getclosed=" + values.getclosed
    + "&srchwords=" + srchwords
    ;

    const url = "https://www.hinasora.com/api/fes/searchFes.php" + param;

    console.info(url);

    setValues(oldValues => ({
      ...oldValues,
      'isLoading': true,
      'message' : "",
    }));
    
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        setValues(oldValues => ({
          ...oldValues,
          'isLoading': false,
        }));
        return response;
      })
      .then((response) => response.json())
      .then((feslist) => {
        let msg = "みつかりませんでした。";
        if (feslist.length > 0) {msg = ""};
        setValues(oldValues => ({
          ...oldValues,
          'feslist': feslist,
          'message': msg,
        }));
      })
      .catch((e) => {
        console.info(e);

        setValues(oldValues => ({
          ...oldValues,
          'message' : "ごめんなさい。エラーです。",
        }));
      });
  }

  function renderSubArea() {

    if (values.category === "free") {
      return (
        <Input
        placeholder="Input Keyword"
        className={classes.inputsub}
        onChange={handleChange}
        name="srchwords"
        value={values.srchwords}
        inputProps={{
          name: 'srchwords',
          id: 'form_srchwords',
        }}
        autoComplete="off"
      />
        );
    } else if(values.category === "fav") {
      return (
        <Select
        value={values.subcategory}
        onChange={handleChange}
        inputProps={{
          name: 'subcategory',
          id: 'form_subcategory',
        }}
        className={classes.inputsub}
        disabled
        >
        <MenuItem value="">
          -
        </MenuItem>
        </Select>
      );
    } else {
      return (
        <Select
        value={values.subcategory}
        onChange={handleChange}
        inputProps={{
          name: 'subcategory',
          id: 'form_subcategory',
        }}
        className={classes.inputsub}
        >
        <MenuItem value="">
          細分未済・全部出ます
        </MenuItem>
        </Select>
      );
    }
  }

  function renderResults() {
    if (values.isLoading) {
      return (
          <CircularProgress className={classes.progress} color="secondary" />
        );
    } else {

      return (
        <div>
        {(values.message !== '') ? <p>{values.message}</p> : ""}
        {values.feslist.map((festival) => (
            <FesCard festival={festival} key={festival.fesId}/>
        ))}

        </div>
      );

    }
  }
  
  return(
      <div className={classes.srchroot}>
        <h3 className={classes.srchtitle}>お祭り探し</h3>
        <div className={classes.srchform} noValidate autoComplete="off">
          <Select
            value={values.category}
            onChange={handleChange}
            inputProps={{
              name: 'category',
              id: 'form_category',
            }}
            className={classes.inputmain}
          >
          {types.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </Select>

          <FormControlLabel
            control={
              <Switch
              size="small"
              checked={values.getclosed}
                onChange={handleChangeChecked}
                inputProps={{
                  name: 'getclosed',
                  id: 'form_getclosed',
                }}
                color="primary"
              />
            }
            label="廃止も"
            className={classes.inputcls}
          />

          {renderSubArea()}

          <Button variant="outlined" size="small" color="primary" className={classes.button} onClick={getList}>
          <SearchIcon className={classes.leftIcon} />
            検索
          </Button>
        </div>
        <div className={classes.srchresults}>
          {renderResults()}
        </div>
      </div>
  );
}


export default SearchContainer;
