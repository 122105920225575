import React from 'react';
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';

// リスト
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import blueGrey   from '@material-ui/core/colors/blueGrey';

// ダイアログ
import Dialog from '@material-ui/core/Dialog';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import FesCard from './FesCard'


const useStyles = makeStyles(theme => ({
  topicsroot: {
    marginTop: 10,
    marginBottom: 50,
    textAlign:'center',
  },
  topicstitle: {
    textAlign:'center',
    lineHeight: '1em',
  },
  topicsmain: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  topicslist: {
    width:'90vw',
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  progress: {
    margin: 8,
  },
  appBar: {
    position: 'relative',
    backgroundColor:'#666666',
  },
  dialogtitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogcontent: {
    paddingTop: '5vw',
    paddingBottom: '10vw',
    bottom       : 0,
    overflow     : 'auto',
    height       : '100%',
    background:'linear-gradient(170deg, #F6F6FC 0%, #FCF6F3 100%) no-repeat 50% 50% / 100% 100%',
},
  dialogcaption: {
    padding      : '5vw',
    paddingTop   : '0vw',
  },
  dialogwarn: {
    padding      : '3vw',
    paddingTop   : '0vw',
    paddingBottom   : '0vw',
    margin       : '2vw',
    marginBottom : '5vw',
    border  : '1px solid #FCB',
    borderRadius: '5px',
    backgroundColor:'#FFF6F0',
  },
  listitemavatar: {
    margin  : 0,
    minWidth: 40,
    padding : 5,
  },
  avatar: {
    margin: 0,
    width: 30,
    height: 30,
    backgroundColor:blueGrey[500],
  },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade in='true' ref={ref} {...props} />;
});

function TopicDialog(props) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    isLoading: false,
    tagname: "",
    feslist: [],
  });

  function getFes(tagname){

    const url = "https://www.hinasora.com/api/fes/getFesByTagName.php?tag=" + tagname;

    setValues(oldValues => ({
      ...oldValues,
      'tagname'  : tagname,
      'isLoading': true,
       feslist: [],
    }));

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        setValues(oldValues => ({
          ...oldValues,
          'isLoading': false,
        }));
        return response;
      })
      .then((response) => response.json())
      .then((feslist) => {
        setValues(oldValues => ({
          ...oldValues,
          'feslist': feslist,
        }));
      })
      .catch((e) => {
        console.info(e);
      });
  }

  if (props.topic.infoTagName){
    if (values.tagname !== props.topic.infoTagName) {
      getFes(props.topic.infoTagName);
    }
  }

  return (
      <Dialog fullScreen disableEscapeKeyDown open={props.open} onClose={() => props.close()}  TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => props.close()} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="subtitle1" className={classes.dialogtitle}>
          {props.topic.infoTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogcontent}>
      <div className={classes.dialogwarn}>
      <Typography variant="body2" color="error">【注】左上の✖ボタンで戻ってください</Typography>
      <Typography variant="caption" color="secondary" >スワイプバックや戻るボタンではうまく戻れません<span aria-label="cat" role="img">😿</span>
      そのうちなんとかします・・・</Typography>
      </div>

      {(() => {
          if(props.topic.infoCaption){
          const arr = props.topic.infoCaption.split(/\r\n|\n/);
          return (
            <div className={classes.dialogcaption}>
              {arr.map((txt, index) => {
               if (txt === "") { txt = "　"; }
               return (
               <Typography variant="body2" color="textSecondary" key={index}>{txt}</Typography>
               )})}
            </div>
            )
          } else {
            return "";
          }
      })()}
      {(() => {
          if (values.feslist.length > 0 && props.topic.infoTagName) {
          return (
            <FesCard festival={values.feslist[0]}/>
            )
          } else {
            return "";
          }
      })()}
      </div>
      </Dialog>
  );
}


function TopicsList(props) {
  const classes = useStyles();
  const { topics, hasError, isLoading } = props;
  const [open, setOpen] = React.useState(false);
  const [curtpc, setcurtpc] = React.useState([]);

  function dialogOpenClose(topic) {
    setOpen(!open);
    setcurtpc(topic);
  }

  if (hasError) {
    return (
    <div className={classes.topicsroot}>
      <h3 className={classes.topicstitle}>インフォメーション</h3>
      <p>データの取得に失敗しました</p>
    </div>
    );
  }
  if (isLoading) {
    return (
      <div className={classes.topicsroot}>
        <h3 className={classes.topicstitle}>インフォメーション</h3>
        <CircularProgress className={classes.progress} color="secondary" />
      </div>
      );
  }

  return (
    <React.Fragment>
      <div className={classes.topicsroot}>
        <h3 className={classes.topicstitle}>インフォメーション</h3>
        <div  className={classes.topicsmain}>
        <List className={classes.topicslist}>

          {topics.map((topic) => (
          <React.Fragment key={'fr' + topic.infoId}>
            <ListItem
                alignItems="flex-start" dense button
                onClick={() => dialogOpenClose(topic)}>
              <ListItemAvatar className={classes.listitemavatar}>
                <Avatar className={classes.avatar}>
                  {topic.infoAvatar}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={topic.infoTitle}
                secondary={topic.infoCaption}
                secondaryTypographyProps={{noWrap:true}}
              />
            </ListItem>
            <Divider variant="middle"/>
          </React.Fragment>
          ))}

        </List>
        </div>
      </div>
      <TopicDialog
      open={open}
      topic={curtpc}
      close={() => dialogOpenClose([])}
      />
    </React.Fragment>

  );
}

TopicsList.propTypes = {
  topics     : PropTypes.array.isRequired,
  hasError   : PropTypes.bool.isRequired,
  isLoading  : PropTypes.bool.isRequired
};


export default TopicsList;
