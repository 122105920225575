import * as actionTypes from '../actions/actionTypes';

export const schedule_getError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SCHEDULE_GET_ERROR:
      return action.hasError;
    default:
      return state;
  }
}

export const schedule_load = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SCHEDULE_LOAD:
      return action.isLoading;
    default:
      return state;
  }
}

export const schedule = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SCHEDULE_FETCH_SUCCESS:
      return action.schedule;
    default:
      return state;
  }
}

const today = (new Date()).getFullYear() + "-"
    + ('0' + ((new Date()).getMonth() + 1)).slice(-2) + "-"
    + ('0' +  (new Date()).getDate()).slice(-2) ;

export const calendarDate = (state = today, action) => {
  switch (action.type) {
    case actionTypes.SCHEDULE_CHG_DATE:
      return action.calendarDate;
    default:
      return state;
  }
}
