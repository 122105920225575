import * as actionTypes from '../actions/actionTypes';

export const getTopicsError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.TOPICS_GET_ERROR:
      return action.hasError;
    default:
      return state;
  }
}

export const loadTopics = (state = false, action) => {
  switch (action.type) {
    case actionTypes.TOPICS_LOAD:
      return action.isLoading;
    default:
      return state;
  }
}

export const topics = (state = [], action) => {
  switch (action.type) {
    case actionTypes.TOPICS_FETCH_SUCCESS:
      return action.topics;
    default:
      return state;
  }
}
