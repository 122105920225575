import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  fbroot: {
    marginTop: 10,
    marginBottom: 10,
    textAlign:'center',
  },
  fbtitle: {
    textAlign:'center',
    lineHeight: '2em',
  },
  fbtext: {
    width : '80vw',
    maxWidth:'500px',
    margin: '0 auto',
    textAlign:'left',
  },
  deltext: {
    textDecoration: 'line-through',
  },
  divider: {
    marginTop: '0px',
    marginBottom: '7px',
    border: '1px solid' ,
    borderColor: 'rgba(255,255,255,1)',
    boxShadow: '0 2px 1px 0px rgba(0,0,0,0.2)',
  
  },

}));

function FeedbackContainer() {
  const classes = useStyles();

  return (
    
    <div className={classes.fbroot}>

      <h3 className={classes.fbtitle}>掲載依頼の受付とサイトポリシー</h3>

      <div className={classes.fbtext}>
      <Typography variant="caption" paragraph color="secondary">お祭り主催者・タグ考案者の方、情報提供いただける方、サイト管理者になんらかのコンタクトを取りたい方は必ずお読みください。それ以外の方はお暇なら。</Typography>

      <Typography variant="subtitle2" color="primary" >当面の運用</Typography><Divider className={classes.divider} />
      <Typography variant="body2"          >将来的には wiki のように、ユーザー参加でコンテンツを充実させていくものにしたいと考えております。</Typography>
      <Typography variant="body2"          >当面はシステム構築が<span className={classes.deltext}> 面倒 </span>&nbsp;追いつかないため、情報の編集はすべて管理者のひなたが実施します。</Typography>
      <Typography variant="body2" paragraph>自分で調べたものと、掲載依頼・情報提供を受けてひなたがアレンジを加えたものを掲載していきます。</Typography>


      <Typography variant="subtitle2" color="primary" >掲載する対象</Typography><Divider className={classes.divider} />
      <Typography variant="body2"          >猫アカウントが主として参加できるお祭り、同好会、合言葉、チャリティ、コンテスト等の各種ハッシュタグを掲載します。</Typography>
      <Typography variant="body2" paragraph>利用のハードルが高そうと判断したタグや、身内で楽しむためのタグなどは、掲載を見送ることがあります。</Typography>


      <Typography variant="subtitle2" color="primary" >タグはみんなのもの</Typography><Divider className={classes.divider} />
      <Typography variant="body2" paragraph>当サイトでは、ハッシュタグを特定個人のものとは捉えていません。
      このため、タグの掲載にあたって誰かに許可を求めるようなことはいたしません。
      同じ理由で、掲載削除のご依頼は基本的に受付けません（ご相談には乗ります）。</Typography>
      <Typography variant="body2"          >ですが、お祭りを運営いただいている主催者の方やタグを考案し広めた方への敬意と感謝の気持ちは誰よりも強いと自負しています。</Typography>
      <Typography variant="body2"          >掲載にあたって主催者・考案者がわかるタグについては、管理者ひなた @hinata_pocky よりフォローの上で、ポストへの書き込みの形でご挨拶させていただきます。</Typography>
      <Typography variant="body2" paragraph>ご挨拶は事後になることが多いと思います。また、ご挨拶目的のDMは控えたいので、コメ欄をあまり開けない方へのご挨拶は遅くなりますことをご容赦ください。</Typography>


      <Typography variant="subtitle2" color="primary" >掲載依頼、情報提供方法</Typography><Divider className={classes.divider} />
      <Typography variant="body2" paragraph>管理者ひなたのインスタグラムアカウントにて随時受付。
      DMでご連絡いただくか、新しめのポストにお気軽にご連絡ください。</Typography>
        <Breadcrumbs aria-label="GotoInstagram">
          <Typography color="textPrimary">@hinata_pocky</Typography>
          <Link color="secondary" href="instagram://user?username=hinata_pocky">
            IG APP
          </Link>
          <Link color="secondary" href="https://www.instagram.com/hinata_pocky/" target="_blank" rel="noopener noreferrer" >
            Browser
          </Link>
        </Breadcrumbs>

        <Typography variant="body2" paragraph> </Typography>
        <Typography variant="body2" paragraph>以下の情報がわかると大変ありがたいです。</Typography>
      <Typography variant="body2"          >・ハッシュタグ名</Typography>
      <Typography variant="body2"          >・簡単な内容紹介</Typography>
      <Typography variant="body2"          >・１日限りのイベントは実施日</Typography>
      <Typography variant="body2"          >・これから始まるものは開始予定日</Typography>
      <Typography variant="body2"          >・期間限定のものは終了予定日</Typography>

      <Typography variant="body2"          >・主催者や考案者のID（わかれば）</Typography>
      <Typography variant="body2"          >・いつ始まったのか（わかれば）</Typography>
      <Typography variant="body2"          >・利用にあたっての注意点（あれば）</Typography>
      <Typography variant="body2"          >・関連タグなど（あれば）</Typography>
      <Typography variant="body2" paragraph>&nbsp;&nbsp;例）#猫後頭部祭 と #猫後頭部倶楽部</Typography>
      <Typography variant="body2" paragraph>紹介者欄へのID記載をしないでほしい場合、その旨をおっしゃってください。
      なお、主催者・考案者ご本人さまからの依頼で掲載したものと、ひなたが勝手に掲載したものには、紹介者欄を記載しません。
      </Typography>


      <Typography variant="subtitle2" color="primary">IDは個人のもの</Typography><Divider className={classes.divider} />
      <Typography variant="body2" >ハッシュタグと異なり、インスタのIDは特定個人のものと考えます。</Typography>
      <Typography variant="body2" paragraph>ご本人様より削除の要請があった場合には、タグの主催者・考案者欄等からIDを削除させていただきますのでDMにてご連絡ください。</Typography>


      <Typography variant="subtitle2" color="primary" >著作権表示</Typography><Divider className={classes.divider} />
      <Typography variant="body2"          >当サイト及び当サイトのコンテンツ、データベース、文章、ロゴマーク、
      その他の著作物に関する著作権は、hinasora.com に帰属します。</Typography>
      <Typography variant="body2" paragraph>個人的な利用を目的とする場合やその他著作権法により認められる場合を除き、
      当サイトのコンテンツを hinasora.com の許諾を得ることなく、複製、公衆送信、改変、ウェブサイトへ転載等する行為は
      著作権法により禁止されています。</Typography>

      <Typography variant="body2"          >って言ってみたかっただけ。改変はやめてほしいけど転載・コピペなどは良識の範囲でご自由にどうぞです。</Typography>
      <Typography variant="body2" paragraph>リンクなども適当にお願いします。ご一報いただけたら喜びます。</Typography>

      <Typography variant="body2" paragraph>当サイトで利用するインスタグラムのロゴマークの著作権はFacebook社に帰属します。</Typography>

      <Typography variant="subtitle2" color="primary">その他ご連絡先</Typography><Divider className={classes.divider} />
      <Typography variant="body2" >インスタグラムのアカウントをお持ちでない方はメールにてご連絡ください。</Typography>
        <Breadcrumbs aria-label="GotoInstagram">
          <Link color="secondary" href="mailto:hinata@hinasora.com">
          hinata@hinasora.com
          </Link>
        </Breadcrumbs>
      </div>

    </div>
  );
}

export default FeedbackContainer;
